<template>
    <AppPanel v-if="!loading" :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/manuais">
        <template #content>
            <AppFormCadastro
                ref="formCadastro"
                :service="service"
                :form="form"
                backPath="/manuais"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <h5 class="mb-4">{{ title }}</h5>
                    <div class="flex flex-column">
                        <div class="flex flex-row column-gap-3 row-gap-6">
                            <div class="field flex-1">
                                <label for="name">Descrição</label>
                                <InputText id="descricao" v-model="form.descricao" autofocus autocomplete="off" />
                            </div>
                            <div class="field flex-1">
                                <label for="name">Módulo</label>
                                <InputText id="modulo" v-model="form.modulo" autocomplete="off" />
                            </div>
                            <div class="field flex-1">
                                <label for="name">Tela</label>
                                <InputText id="tela" v-model="form.tela" autocomplete="off" />
                            </div>
                        </div>
                    </div>
                    <div class="field flex-1">
                        <label for="anexos">Anexar manual</label>
                        <FileUpload
                            v-if="!loading"
                            auto
                            customUpload
                            mode="advanced"
                            chooseLabel="Escolha o arquivo"
                            :showCancelButton="false"
                            :showUploadButton="false"
                            :maxFileSize="10485760"
                            invalidFileSizeMessage="O arquivo selecionado excede o tamanho de 10 Mb."
                            @uploader="uploadAnexo"
                        >
                            <template v-if="!anexos.length" #empty>
                                <div class="flex align-items-center justify-content-center flex-column text-500">
                                    <i class="pi pi-cloud-upload border-2 border-circle p-5 text-8xl border-500" />
                                    <p class="mt-4 mb-0">Arraste e solte o arquivo aqui.</p>
                                </div>
                            </template>
                            <template #content>
                                <DataTable
                                    v-if="anexos.length"
                                    dataKey="id"
                                    :value="anexos"
                                    :row-hover="true"
                                    class="p-datatable-sm"
                                    responsiveLayout="stack"
                                    breakpoint="640px"
                                >
                                    <template #empty> Nenhum registro encontrado. </template>
                                    <Column field="nomeArquivo" header="Nome">
                                        <template #body="{ data }">
                                            <a
                                                :href="data.url"
                                                class="white-space-nowrap overflow-hidden text-overflow-ellipsis"
                                                style="max-width: 60vw"
                                                target="_blank"
                                            >
                                                {{ data.nomeArquivo }}
                                            </a>
                                        </template>
                                    </Column>
                                    <Column field="tipo" header="Tipo"></Column>
                                    <Column field="createdAt" header="Data">
                                        <template #body="{ data }">
                                            {{ $filters.formatDate(data.createdAt) }}
                                        </template>
                                    </Column>
                                    <Column style="min-width: 2rem">
                                        <template #body="slotProps">
                                            <Button
                                                v-if="slotProps.data.origemId"
                                                icon="pi pi-cloud-download"
                                                class="p-button-text p-button-secondary"
                                                @click="downloadAnexo($event, slotProps.data)"
                                            />
                                            <Badge v-else value="Novo"></Badge>
                                        </template>
                                    </Column>
                                    <Column style="min-width: 2rem">
                                        <template #body="{ data }">
                                            <Button icon="pi pi-trash" class="p-button-text p-button-secondary" @click="removerAnexo(data)" />
                                        </template>
                                    </Column>
                                </DataTable>
                            </template>
                        </FileUpload>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
</template>

<script>
import BaseService from '../../services/BaseService';
import TipoAnexoEnum from '../../enums/TipoAnexoEnum';
import { getClientBase } from '../../services/http';
import OrigemAnexoEnum from '../../enums/OrigemAnexoEnum';
import axios from 'axios';
import { showError, showWarning } from '../../utils/Toast';

export default {
    data() {
        return {
            form: {},
            loading: false,
            submitted: false,
            modulosOptions: [],
            telas: [],
            telasOptions: [],
            anexos: [],
            cloneAnexos: [],
            voltarAoSalvar: true
        };
    },
    created() {
        this.service = new BaseService('/manuais-sistema');
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editar manual do sistema' : 'Adicionar manual do sistema';
        }
    },
    methods: {
        async onLoadDataEdit(data) {
            this.form = data;
            if (data.id) {
                const responseAnexos = await getClientBase().get(`/anexo/${OrigemAnexoEnum.MANUAL_SISTEMA}/${data.id}`);
                this.anexos = responseAnexos.data;
                this.cloneAnexos = responseAnexos.data;
            }
        },
        uploadAnexo(event) {
            if (this.anexos.length) {
                showWarning(this.$toast, 'Já existe um arquivo anexado. Remova e tente novamente.');
                return;
            }

            this.anexos.push({
                nomeArquivo: event.files[0].name,
                arquivo: event.files[0],
                tipo: TipoAnexoEnum.MANUAL
            });
        },
        removerAnexo(anexo) {
            this.anexos = this.anexos.filter((p) => p.nomeArquivo !== anexo.nomeArquivo);
        },
        downloadAnexo($event, record) {
            axios({
                url: record.url,
                method: 'GET',
                responseType: 'blob'
            }).then((res) => {
                const file = window.URL.createObjectURL(new Blob([res.data]));

                const docUrl = document.createElement('a');
                docUrl.href = file;
                docUrl.setAttribute('download', record.nomeArquivo);
                document.body.appendChild(docUrl);
                docUrl.click();
            });
        },
        async onBeforeSave() {
            const anexosIdsExclusao = this.cloneAnexos
                ?.map((p) => p.id)
                ?.filter((id) => !this.anexos.some((anexo) => anexo.id === id));

            if (this.hasError()) {
                showWarning(this.$toast, 'Anexo obrigatório');
                return;
            }

            for await (const id of anexosIdsExclusao) {
                await getClientBase().delete(`/anexo/${id}`);
            }
        },
        async onAfterSave(response) {
            try {
                if (response?.data) {
                    this.form = response.data;
                    const anexosParaSalvar = this.anexos.filter((p) => !p.id);

                    if (!anexosParaSalvar.length) {
                        this.submitted = false;
                        return;
                    }

                    for (const anexo of anexosParaSalvar) {
                        const formData = new FormData();

                        formData.append('file', anexo.arquivo);
                        formData.append('origem', OrigemAnexoEnum.MANUAL_SISTEMA);
                        formData.append('origemId', this.form.id);
                        formData.append('tipo', TipoAnexoEnum.MANUAL);

                        await this.anexarArquivo(formData);
                    }
                }
                this.submitted = false;
            } catch (err) {
                showError(this.$toast, err, 'Problemas ao carregar o arquivo');
            }
        },
        async onValidate(cbSuccess, cbError) {
            if (this.hasError()) {
                return cbError();
            }

            return cbSuccess();
        },
        hasError() {
            if (!this.anexos.length) return true;
        },
        async anexarArquivo(formData) {
            await getClientBase().post('/anexo', formData);
        }
    }
};
</script>

<style scoped lang="scss">
.p-fileupload {
    width: 50%;
}

:deep(.p-fileupload-buttonbar .p-fileupload-choose) {
    margin: 0;
    width: 100%;
}

:deep(.p-fileupload-content) {
    background: #ffffff;
    padding: 2rem 1rem;
    border: 1px solid #dee2e6;
    color: #495057;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

:deep(.p-fileupload-file) {
    padding: 1rem;
    border: 1px solid #dee2e6;
    border-radius: 6px;
    gap: 0.5rem;
}

:deep(.p-fileupload-file-badge),
:deep(.p-fileupload-file-size) {
    display: none;
}

:deep(.p-fileupload-file-remove) {
    aspect-ratio: 1/1;
}
</style>
